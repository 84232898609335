export default {
  newShare: "شارك واربح",
  title: "زياء ",
  welcome: "مرحبا بكم في منصة زياء للتسوق الالكتروني !",
  welcomeShort: "مرحبا بكم في منصة زياء للتسوق الالكتروني !",
  Happy:"الحياة سعيدة ^_^",
  payment: "الدفع عند الاستلام !",
  zh: "中文",
  en: "English",
  ar: "العربية",
  zhMoney: "CNY",
  download: "للتحميل",
  moneyMark: "¥",
  exit: "تسجيل الخروج",
  goods: "المنتجات",
  search: "بحث",
  searchPh: "الرجاء إدخال كلمات البحث",
  wish: "مشاركة",
  collect: "المفضلة ",
  cart: "عربة التسوق",
  home: "الرئيسية",
  allBrand: "جميع الماركات ",
  allStore: "جميع المتاجر",
  coupon: "كوبونات",
  couponA: "كوبونات:",
  partyEntry: "الفعاليات",
  sellerCenter: "مركز البائعين",
  concat: "تواصل معنا ",
  about: "تعريف المنصة",
  open: "فتح متجر",
  scoreRule: "نظام نقاط المكافئات",
  qa: "الأسئلة الشائعة",
  map: "خريطة الموقع",
  paymentOptions: "طرق الدفع",
  paypall: "PayPal",
  stripe: "Stripe",
  receivePay: "الدفع عند الاستلام ",
  fovar: "تابعونا ",
  facebook: "Facebook",
  tweeter: "X",
  youtube: "Youtube",
  instaram: "Instagram",
  private: "سياسة الخصوصية",
  orderTerm: "شروط وأحكام الفواتير",
  copyright: "جميع الحقوق محفوظة زياء © 2023 ",
  beian: "沪公网安备 3101000000002948号 | 沪ICP备15000000号-1",
  allCategories: "جميع الأقسام",
  viewAll: "عرض الجميع",
  backToLastPage: "العودة إلى الصفحة السابقة",
  messages: "قائمة الرسائل",
  myCoupon: "كوبوناتي ",
  all: "الكل",
  used: "مستخدم",
  unused: "غير مستخدم",
  expired: "منتهي الصلاحية",
  seller: "البائعين",
  platform: "منصة",
  discount: "تخفيض",
  goUse: "استخدام",
  myCollection: "مفضلتي",
  salesTitle: "تفاصيل الطلب",
  checkAll: "تحديد الكل",
  removeCollection: "حذف من المفضلة",
  total: "مجموع {total} صفحة",
  myOrder: "طلبياتي ",
  key: "الكلمات المفتاحية",
  keyPh: "الكلمات المفتاحية لعنوان المنتج",
  orderType: "نوع الطلب",
  orderTypePh: "الرجاء تحديد نوع الطلب",
  orderStatus: "حالة الطلب",
  orderStatusPh: "الرجاء تحديد حالة الطلب",
  orderTime: "توقيت الطلب",
  startTime: "من ",
  endTime: "إلى",
  to: "إلى",
  allOrder: "جميع الطلبات",
  unPay: "بانتظار الدفع",
  unSend: "بانتظار الشحن",
  unReceive: "بانتظار الاستلام",
  unAssem: "بانتظار التقييم",
  maintaining: "ما بعد البيع",
  search: "بحث",
  baby: "المنتج",
  price: "السعر",
  count: "الكمية",
  paiedMoney: "المبلغ المطلوب",
  action: "إجراء",
  orderTimeA: "توقيت الطلب:",
  orderNo: "رقم الطلب: ",
  containPostFee: "يتضمن تكاليف الشحن: ",
  orderDetail: "تفاصيل الطلب",
  pay: "تأكيد",
  cancel: "إلغاء",
  applyReback: "طلب استرداد المبلغ",
  confirmReceive: "تأكيد الاستلام",
  viewTansform: "تتبع الطلب",
  applyMaintain: "ما بعد البيع",
  goAssem: "تقييم",
  recall: "إلغاء خدمة ما بعد البيع",
  platformAppeal: "تقديم شكوى",
  viewAssem: "تصفح التقييمات",
  confirm: "إرسال",
  confirmReceived: "تأكيد الاستلام",
  close: "غلق",
  ok: "تأكيد",
  transformCompany: "شركة التوصيل",
  transformNo: "رقم تتبع الشحنة",
  confirmReceiveGoods: "هل قمت باستلام الطلب والتأكد منه؟",
  myWishList: "مشاركاتي",
  removeWishList: "الإلغاء من القائمة",
  copyLink: "نسخ الرابط",
  orderRecords: "سجل الطلبات",
  myAddress: "عناويني",
  receiver: "المستلم",
  receiverPh: "الرجاء إدخال إسم المستلم",
  concatPhone: "رقم التواصل",
  concatPhonePh: "الرجاء إدخال رقم التواصل",
  receiveAddress: "عنوان المستلم",
  receiveAddressPh: "الرجاء تحديد البلد - المحافظة - المدينة - المنطقة",
  detailAddress: "العنوان الكامل",
  detailAddressPh: "الرجاء إدخال العنوان الكامل (تحديد رقم المنزل)",
  postCode: "الرمز البريدي",
  postCodePh: "الرجاء إدخال الرمز البريدي",
  required: "لا يمكن ترك الخانة فارغة {t}",
  minMax: "الطول {max} من {min} إلى {t}",
  min: "{min} الحد الأدنى للطول {t}",
  max: "{t} الحد الأقصى للطول {max}",
  addAddress: "إضافة عنوان جديد",
  save: "حفظ",
  myAddresses: "عناوين الاستلام الخاصة بي",
  belongsToAddress: "المنطقة",
  mobileOrPhone: "البريد الإلكتروني/ رقم الهاتف المحمول",
  defaultAddress: "العنوان الرئيسي",
  setDefaultAddress: "تحديده كعنوان رئيسي",
  delete: "حذف",
  orderUser: "الزبون:",
  orderGoods: "المنتج:",
  xiadanDate: "التاريخ: ",
  score: "نقاط المكافئات",
  moneyCoupon: "يوان كوبونات",
  viewMore: "إظهار المزيد",
  partyTime: "تاريخ الفعالية:",
  newCoupon: "كوبونات الزبائن الجدد",
  newEnjoy: "حصريا للزبائن الجدد، اتمتع بتخفيضات عند الشراء",
  immediatelyGet: "احصل عليه الآن",
  sort: "ترتيب",
  salesVolume: "حجم المبيعات",
  filter: "فرز",
  priceRange: "نطاق السعر",
  choseBrand: "اختيار العلامة التجارية",
  choseBrandPh: "الرجاء اختيار العلامة التجارية",
  reset: "إعادة ضبط",
  credit: "المصداقية",
  storeType: "نوع المتجر ",
  storeTypePh: "الرجاء اختيار نوع المتجر",
  choseArea: "اختيار المنطقة",
  choseAreaPh: "الرجاء اختيار المنطقة",
  changeStyle: "تغيير النمط",
  fans: "المعجبين:",
  selfSell: "مباشرة إلى المستهلك",
  enterStore: "الدخول إلى المتجر",
  money: "المبلغ",
  storeName: "إسم المتجر",
  moveToCollection: "نقل إلى المفضلة ",
  addToWishlish: "إضافة إلى قائمة المشاركات",
  pleaselogin: "يرجى تسجيل الدخول أولاً",
  payMoney: "الدفع",
  amount: "المجموع (باستثناء رسوم الشحن)",
  checked: "تم الاختيار",
  jian: "قطعة / قطع ",
  iWantleaveMessage: "ترك رسالة",
  inputMessage: "الرجاء إدخال نص الرسالة...",
  confirmMessage: "إرسال الرسالة",
  searchResult: "نتائج البحث",
  appeal: "الشكاوى",
  confirmShouhou: "إرسال طلب ما بعد البيع",
  appealDesc: "تفاصيل الشكوى",
  uploadImage: "تحميل صورة",
  appealDescPh: "الرجاء إدخال تفاصيل الشكوى",
  shouhouType: "نوع خدمة ما بعد البيع",
  shouhouTypePh: "الرجاء اختيار نوع خدمة ما بعد البيع",
  shouhouGoods: "المنتج المباع",
  repayMoney: "استرداد المبلغ",
  repayMoneyPh: "الرجاء إدخال المبلغ المطلوب استرداده",
  repayReason: "سبب الاسترداد",
  repayReasonPh: "الرجاء إدخال سبب استرداد المبلغ",
  transformInfo: "معلومات الشحن",
  transformStatus: "حالة التسليم",
  transformSendInfo: "معلومات التسليم",
  choseReceiveAddress: "اختيار عنوان التسليم",
  receiveInfo: "معلومات الاستلام",
  modifyAddress: "تعديل العنوان",
  confirmOrderInfo: "تأكيد تفاصيل الطلب",
  storeNameHeader: "إسم المتجر",
  xiaoji: "المجموع الفرعي",
  amountContainPostfee: "المجموع (يتضمن مصاريف الشحن)",
  paiedMoneyColon: 'المجموع:',
  Producttotal:'سعر البضاعة:',
  Shippingfee:'الشحن:',
  confirmOrder: "إرسال الطلب",
  remark: "ملاحظات:",
  postType: "طريقة الشحن",
  post: "البريد السريع",
  chosePayment: "اختيار طريقة الدفع",
  gotoPay: "تأكيد ",
  finishOrder: "تم إرسال الطلب، يرجي انتظار الاستلام !",
  back: "رجوع",
  viewOrder: "عرض الطلب",
  cashOnDeliveryTip:
    " فقط الأعضاء المسجلون يمكنهم التحقق من تفاصيل الطلب وتأكيد الاستلام والقيام بعمليات الإرجاع والاستبدال ويتمتعون بميزات العروض والتخفيضات",
  continuePay: "استمر في الدفع",
  registerVIP: "التسجيل",
  currentBrand: "العلامة التجارية الحالية",
  toBeSeller: "أصبح بائعا",
  sellerName: "إسم البائع",
  sellerNamePh: "الرجاء إدخال إسم البائع",
  invalidPhone: "صيغة رقم الجوال غير صحيحة",
  emailAddress: "عنوان البريد الإلكتروني",
  emailAddressPh: "الرجاء إدخال عنوان البريد الإلكتروني",
  invalidEmail: "عنوان البريد الإلكتروني غير صحيح",
  storeAddress: "عنوان المتجر",
  choseCountryPh: "اختر البلد",
  choseProvincePh: "اختر المحافظة ",
  choseCityPh: "اختر المدينة",
  manageClass: "فئة الأعمال",
  brandAuth: "وثائق التخويل الخاصة بالعلامة التجارية",
  clickUpload: "تحميل",
  single: "(اختيار واحد)",
  manageLicense: "الرخصة التجارية وشهادات التأهيل ذات الصلة بمجال العمل",
  exclusiveShop: "متجر متخصص",
  flagshipStore: "متجر رئيسي",
  typeShow: "عرض حسب النوع",
  joinDesc: "التسجيل يعني الموافقة على أحكام هذه المنصة",
  joinAgreeOn: '"إتفاقية التسجيل"',
  auditStatus: "حالة الموافقة",
  auditing: "قيد التدقيق",
  auditSuccess: "تم القبول",
  failReason: "أسباب الرفض",
  auditSuccessResult:
    "تم إرسال إشعار حساب التاجر وكلمة المرور عبر البريد الإلكتروني/الرسائل القصيرة ، يرجى التحقق. إذا كانت لديكم أي أسئلة، يرجى الاتصال بخدمة العملاء.",
  reSubmit: "إعادة تقديم",
  qa: "الأسئلة الشائعة",
  privateTerm: "سياسة الخصوصية",
  scoreRule: "نظام نقاط المكافئات",
  billTerm: "شروط وأحكام الفواتير",
  id: "{v} :ID",
  editInfo: "تعديل المعلومات",
  collectGoods: "المنتجات المفضلة",
  collectStore: "المتاجر المفضلة",
  username: "أسم المستخدم",
  usernamePh: "الرجاء إدخال إسم مستخدم",
  phone: "رقم الجوال",
  unbind: "غير مرتبط",
  emailAccount: "حساب البريد الإلكتروني",
  emailAccountPh: "الرجاء إدخال حساب البريد الإلكتروني",
  password: "رورملا ةملك",
  passwordPh: "الرجاء إدخال كلمة المرور",
  inputPasswordAgain: "الرجاء إعادة إدخال كلمة المرور",
  confirmPassword: "تأكيد كلمة المرور",
  bindPhone: "ربط رقم الجوال",
  changeEmail: "تغيير البريد الإلكتروني",
  concatSeller: "التواصل مع البائع",
  focusOnStore: "إلغاء المتابعة",
  immediatelyGetBr: "الآن<br />احصل عليه ",
  couponGoods: "المنتجات التالية تشارك في الفعالية:",
  hadAccount: "لديك حساب؟",
  gotoLogin: "تسجيل الدخول",
  noAccount: "لا تملك حسابا حتى الآن؟",
  gotoRegister: "اشترك الآن",
  forgotPassword: "نسيت كلمة المرور",
  register: "الاشتراك",
  accountPh: "اسم مستخدم / رقم الجوال / البريد الإلكتروني",
  login: "تسجيل الدخول",
  otherLoginType: "طرق تسجيل الدخول الأخرى",
  emailRegister: "تسجيل البريد الإلكتروني",
  phoneRegister: "تسجيل رقم الجوال",
  capthcaPh: "الرجاء إدخال رمز التحقق",
  getCaptcha: "الحصول على رمز التحقق",
  otherRegisterType: "طرق تسجيل الدخول الأخرى",
  bindEmail: "ربط البريد الإلكتروني",
  bindPhone: "ربط رقم الجوال",
  phonePh: "الرجاء إدخال رقم الجوال",
  recommendGoods: "المنتجات الموصى بها",
  allGoods: "جميع المنتجات",
  store: "المتاجر",
  brand: "العلامات التجارية",
  quotationMark: '"{v}"',
  passwordNotSame: "كلمات المرور غير متطابقة",
  captcha: "ققحتلا زمر",
  invalidEmail: "صيغة البريد الإلكتروني غير صحيحة",
  secondResend: "سيتم الإرسال بعد {s} ثواني (ثانية)",
  invalidPhone: "رقم الجوال غير صحيح",
  askExit: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
  bind: "ربط",
  askDelete: "هل أنت متأكد أنك تريد الحذف؟",
  emailFind: "استرجاع الحساب عبر البريد الإلكتروني",
  phoneFind: "استرجاع الحساب عبر رقم الجوال",
  changePhone: "تغيير رقم الجوال",
  oldEmailAccount: "حساب البريد الإلكتروني السابق",
  newEmailAccount: "حساب البريد الإلكتروني الجديد",
  oldPhone: "رقم الجوال السابق",
  newPhone: "رقم الجوال الجديد",
  oldPassword: "كلمة المرور السابقة",
  newPassword: "كلمة المرور الجديدة",
  joinAgreement: "إتفاقية التسجيل",
  hotRecommend: "الأكثر توصية",
  zhekou: "<span>{v} ٪</span>خصم",
  xiadanzhekou: "احصل على خصم",
  cancelFocus: "تم الغاء المتابعة بنجاح",
  allA: "الكل",
  unreadAgreement: 'يرجى قراءة "إتفاقية التسجيل" والضغط على موافق',
  shopPic: "صور المتجر",
  rejected: "رفض",
  passed: "معتمد",
  goodsDetail: "تفاصيل المنتج",
  goodsComment: "تعليقات على هذا المنتج",
  originPrice: "السعر الأصلي: ",
  priceA: "السعر: ",
  collectA: "حفظ في المفضلة",
  salesed: "تم بيع:{v}",
  guessYouLike: "قد يعجبك أيضا",
  commentCount: "تعليقات (تعليق){v}",
  activeEndTime: "وقت إنتهاء الفعالية:",
  sendTime: "موعد التسليم:{v}",
  sellerA: "البائع:{v}",
  viewShop: "عرض المتجر",
  addToCart: "أضف إلى عربة التسوق",
  immediatelyBuy: "اشتري الآن",
  topSalesGoods: "الأكثر مبيعا",
  rateScore: "تقييم: ",
  giveScore: "النقاط:{v}",
  shou: "استلام",
  choseReceiveAddressA: "الرجاء إختيار عنوان التسليم",
  shouhoubaozhang: "خدمة ما بعد البيع",
  recommendBrand: " توصية العلامة التجارية ",
  tradeDone: "إنتهاء المعاملة",
  askRepayback: "هل أنت متأكد أنك تريد إلغاء الطلب واسترداد الأموال؟",
  confirmReceiveA: "هل قمت باستلام الطلب والتأكد منه؟",
  changeAvatar: "تغيير صورة الملف الشخصي",
  countLessOne: "لا يمكن أن تكون الكمية أقل من 1",
  noSpec: "الرجاء تحديد المواصفات",
  msgEmpty: "محتوى الملاحظة لا يمكن أن يكون فارغا",
  choseCoupon: "الرجاء اختيار كوبون",
  maintainRejected: "رفض تقديم خدمة ما بعد البيع",
  tradeCancel: "إلغاء المعامة ",
  askReturn: "هل أنت متأكد أنك تريد الانسحاب؟",
  askCancel: "هل أنت متأكد أنك تريد الإلغاء؟",
  concater: "المتصل به: ",
  concatPhoneA: "رقم الإتصال: ",
  receiveAddressA: "عنوان الاستلام: ",
  transformCom: "شركة التوصيل:",
  transformNoA: "رقم تتبع الشحنة:",
  payTime: "توقيت الدفع: ",
  paymentType: "طريقة الدفع:",
  orderInfo: "تفاصيل الطلب",
  sendTimeA: "موعد التسليم:",
  maintainTime: "توقيت ما بعد البيع",
  maintainDesc: "وصف ما بعد البيع",
  returnType: "نوع الاسترداد:",
  returnMoney: "المبلغ المسترجع:",
  sellerRecall: "رد التاجر:",
  appealDesc: "وصف الشكوى",
  viewTransformInfo: "عرض معلومات الشحن",
  onlyReturnMoney: "استرداد المبلغ فقط",
  returnMoneyAndGoods: "إرجاع المنتج واستردادالمبلغ",
  singlePrice: "سعر الوحدة",
  totalPrice: "سعر المنتج:",
  postFee: "تكاليف الشحن:",
  returnPoint: "النقاط التي يمكن استردادها: {v} نقاط (نقطة)",
  maintainPassed: "خدمة ما بعد البيع تمت بنجاح",
  maintainUnsend: "تمت الموافقة على خدمة بعد البيع، بانتظار إعادة المنتج",
  appealling: "مراجعة الشكوى",
  appealUnpass: "تم رفض الشكوى",
  appealPassed: "تم قبول الشكوى",
  sendUnConfirm: "بانتظار تأكيد استرجاع المنتج",
  buyGoods: "إرسال طلب المنتج",
  sellerSendGoods: "البائع أرسل الطلب",
  commentOrder: "تقييم الطلب",
  orderDone: "تم اكتمال الطلب",
  editInfo: "تعديل المعلومات",
  leftPayTime: "الوقت المتبقي للدفع:{v}",
  cancelOrder: "إلغاء الطلب",
  repayBack: "استرداد المبلغ",
  descMatch: "يتطابق الوصف مع:",
  commentPh: "يمكن التعليق ب 300 كلمة كأقصى حد",
  image: "صورة: ",
  commentContentEmpty: "لا يمكن ترك محتوى التعليق فارغًا",
  singlePriceA: "سعر الوحدة",
  leftReceiveTime:
    "تبقى {v} أيام (يوم) وسيتم تأكيد طلبك تلقائيًا. في حال لم تستلم الطلب، الرجاء الاتصال بالبائع!",
  maintainNoGoods:
    "يرجى تحديد منتج واحد على الأقل للتقديم على خدمة ما بعد البيع",
  repaybacking: "قيد الاسترداد",
  cancelAppealling: "سحب الشكوى",
  sendBack: "إرجاع المنتج",
  viewSendBackTransform: "عرض تفاصيل الشحن لإرجاع المنتج",
  appealA: "تقديم شكوى",
  confirmA: "تأكيد",
  askCancelAppeal: "هل أنت متأكد أنك تريد سحب الشكوى؟",
  refundDesc: "رد الوصف :",
  chineseClass: "الأقسام في النسخة الصينية",
  englishClass: "الأقسام في النسخة الانجليزية",
  arClass: "الأقسام في النسخة العربية",
  paySuccess: "دفع النجاح",
  payFailed: "فشل الدفع",
  day: "أيام . ",
  returnAddress: "عنوان الارجاع:",
  unchoseGood: "الرجاء اختيار المنتج",
  balance: "رصيد المحفظة:",
  viewDetail: "عرض التفاصيل",
  currentBalance: "الرصيد الحالي",
  amountBalance: "الرصيد المتراكم",
  paymentPassword: "كلمة مرور الدفع",
  recharge: "فوق حتى",
  earnDetail: "تفاصيل الدخل",
  payDetail: "تفاصيل النفقات",
  platAdjust: "تعديل المنصة",
  consume: "استهلاك",
  trandeType: "نوع المعاملة",
  adjustReason: "سبب التعديل:",
  moneyA: "المبلغ:",
  time: "وقت:",
  unbindPhone: "رقم الهاتف المحمول غير منضم",
  unbindEmail: "بريد إلكتروني غير منضم",
  validEmail: "تأكيد بواسطة البريد الالكتروني",
  validPhone: "التحقق من رقم الهاتف المحمول",
  setPaymentPasswordTip: "الرجاء إدخال كلمة مرور الدفع المكونة من 6 أرقام",
  rechargeMoney: ":مبلغ إعادة الشحن",
  rechargeMoneyPh: "الرجاء إدخال مبلغ إعادة الشحن ",
  paymentOptionsA: "طريقة الدفع:",
  paginationTip: "اضغط Enter",
  qicard: "كي كارد",
  unsetPaymentPassword: "الرجاء تعيين كلمة المرور للدفع",
  mixPay: "دفع مشترك",
  balancePay: "دفع من الرصيد",
  currentPoints: "النقاط الحالية",
  amountPoints: "النقاط المتراكمة",
  RedeemPoints: "استبدال النقاط",
  Purchase : "شراء",
  Share : "مشاركة",
  All:'الكل',
  Underreview:"قيد المراجعة",
  Approved:'تمت الموافقة',
  Reviewrejected:"تم رفض المراجعة",
  ApprovalStatus:"حالة الموافقة",
  ApplicationTime:"وقت التقديم",
  redeemedNumber:"عدد النقاط المراد استبدالها",
  Reasonforrejection:"سبب الرفض",
  RedeemAll:"استبدال الكل",
  EstimatedRedemptionAmount:"مبلغ الاستبدال  المتوقع",
  RedemptionInstructions:"إرشادات الاستبدال",
  canredeempoints:"يمكنك الاستبدال فقط عندما يكون لديك أكثر من *** نقاط ",
  pointConfirm:'تأكيد',
  jifen:'النقاط',
  orderUser:"طلب المستخدم",
  OrderNo:'رقم الطلب',
  orderGoods:"طلب السلع",
  orderTime:"وقت الطلب",
  exchangesuccess:"تم تقديم طلب الأستبدال بنجاح, في انتظار المراجعة",
  nothaveenoughpoints:"ليس لديك ما يكفي من النقاط",
  int_tip:"دزبندی ایں کہ یک انٹیجرے درج کن",
  email:"البريد الإلكتروني",
	Contactnumber:"رقم الاتصال",
	Message:"رسالة",
  Sendemail:'إرسال البريد الإلكتروني',
	Pleaseenterthecorrectemailaddress:'الرجاء إدخال عنوان البريد الإلكتروني الصحيح',
	Pleasefillintheinformationandsubmit:'يرجى ملء المعلومات وإرسالها',
  sendsuccess:'تم التقديم بنجاح',
  username:'اسم',
  alipay:'Alipay',
  paysuccess:"تأكيد إتمام الدفع?",
  SystemModification:"تعديل النظام",
  Rechargebalance:'شحن الرصيد'
};
